<template>
    <div
        v-loading="loading"
        element-loading-background="rgba(122, 122, 122, 0.1)"
        element-loading-text="Please wait"
        :element-loading-spinner="svg"
        element-loading-svg-view-box="-10, -10, 50, 50"
        style="z-index: 1"
    >
        <div class="card card-lead-by-sources">
            <vc-report-filter 
                v-model:modelStartDate="filter.start_date"
                v-model:modelEndDate="filter.end_date"
                v-model:modelAgent="filter.agent"
                show-agent
                show-export-reset-first-row
                @reset="reset"
                @export="exportData"
                @change="resetToPage"
                :loading-export="disableExport"
                :export-url="exportUrl"
                is-agent-multiple
            />

            <div class="card-body card-custom-spacing-tblf-10 bg-white">
                <div class="d-flex search-block lead-filter-buttons mb-5" :class="{'justify-content-end': !textMessage}" :style="textMessage ? 'justify-content: space-between' : ''">
                    <a
                        class="leads nav-link alert bg-light-danger align-items-center leads-btn"
                    >
                        {{ theTable.total }}
                        Record Count
                    </a >
                </div>
                <div class="pt-0 p-0" v-if="deletedLeads && deletedLeads.length > 0">
                    <div id="displayResponseData">

                        <!-- class="table table-hover bg-bai-table-header table-bai-striped table-row-bordered border gy-7 gs-7 mb-0" -->
                    <el-table 
                        :data="deletedLeads"
                    >
                        <el-table-column
                            v-for="list in deleted_leads_table"
                            :key="list.label"
                            :fixed="list.isFixed"
                            :width="list.width"
                        >
                            <template #header>
                                <span v-html="list.label" />
                            </template>
                            <template #default="scope">
                                <span v-if="list.isIndex"> {{ scope.$index + (srs + 1) }} </span>
                                <span v-else-if="list.isFormat"> {{ common.setDateTimeOnly(scope.row[list.value]) }} </span>
                                <span v-else-if="list.isCount">
                                    <span v-for="(n, index) in scope.row[list.value]" :key="index">
                                        {{ index + '('+n+'), ' }}
                                    </span>
                                </span>
                                <span v-else
                                :class="list.cn"
                                v-html="scope.row[list.value]" />
                            </template>

                        </el-table-column>

                    </el-table>
                    </div>
                    
                </div>
                <div v-else>
                    <div v-if="loading">
                        <span>Loading Information Please Wait...</span>
                    </div>
                    <div v-else class="container-fluid p-5">
                    <div class="row">
                        <div class="col-12">
                        <el-empty description="No Records Found" />
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div class="card-footer bg-white">
                <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-4">
                    <div class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                        <div class="dataTables_length" id="kt_customers_table_length">
                        <label>
                            <el-select 
                                v-model="filter.per_page"
                                @change="currentPage = 1"
                                size="large"
                                style="width: 100%"
                            >
                                <el-option v-for="page in per_pages" :key="page" :value="page"> {{ page }} </el-option>
                            </el-select>
                        </label>
                        </div>
                    </div>
                    </div>
                    <div class="col-4 text-center">
                        <span class="text-muted d-block mt-2">
                        Showing
                        <span v-if="theTable.total > 0">
                            {{ theTable.from }} to {{ theTable.to }} of
                        </span>
                        <span v-else>
                            {{ theTable.total }} of 
                        </span>
                        {{ theTable.total }}
                        </span>
                    </div>
                    <div class="col-4">
                    <div style="float: right">
                        <el-pagination v-if="theTable" v-model:currentPage="currentPage"
                        :page-size="parseInt(theTable.per_page)" :small="large" layout="prev, pager, next"
                        :total="theTable.total" />
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Report from '@/core/services/Callable/Report'
import { ref, watch, computed, onMounted } from 'vue'
import { store } from '@/store'
import { deleted_leads_table } from '@/core/utils/reports'
import { per_pages } from '@/core/utils/common'
import moment from 'moment';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { common } from '@/store/stateless'

const filter = ref({
    start_date: '',
    end_date: '',
    agent: '',
    per_page: 50
})


    onMounted(async() => {
        setCurrentPageBreadcrumbs("Deleted Leads", ['Reports']);
        reset()

        // await searchData(filter.value)

    })

const loading = ref(false)
const disableExport = ref(false)
const currentPage = ref(1)
const searchy = ref([])
const exportUrl = ref('')
function reset() {
    const date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    filter.value.start_date =  moment(firstDay).format("YYYY-MM-DD")
    filter.value.end_date =  moment(lastDay).format("YYYY-MM-DD")

    filter.value.agent = ''
    filter.value.page = 1
    // filter.value.per_page = 2
}

async function exportData() {
    const filty = {
        ...filter.value,
        export: true
    }
    disableExport.value = true
    await searchData(filty)
}

const deletedLeads = computed(() => {
        return store.getters.getDeletedLeads
    })

const theTable = computed(() => {
        return store.state.report.deleted_leads
    })

watch([() => filter.value, () => currentPage.value], async([newValue, newCurrent], [oldValue]) => {
        newValue.page = newCurrent
    exportUrl.value = '';
        
            searchData(newValue)

    }, {
        deep: true
    })

async function searchData(filty) {
    loading.value = true
    const data = await Report.getReportDeletedLeads(filty)
    disableExport.value = false
    loading.value = false
    window.scrollTo(0, 0)
    if(!filty.export)
        searchy.value = data
    else {
        exportUrl.value = data.data;
        // window.open(data.data)
    }
}

function resetToPage() {
    filter.value.page = 1
}


const srs = computed(() => {
    return (filter.value.page - 1) * filter.value.per_page
})
</script>
